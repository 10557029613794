import React from 'react';
import './Display.css'
import Sound from "react-sound";

class LetterSound extends React.Component {
    render() {
        let soundFileName = this.props.fileName;
        return (<Sound
            url={process.env.PUBLIC_URL + "/" + soundFileName}
            playStatus={Sound.status.PLAYING}
        />);
    }
}

export default LetterSound;
