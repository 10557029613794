import React from 'react';
import './Display.css'

class Display extends React.Component {
    render() {
        return (<div className="Display">
            {this.props.letter}
        </div>);
    }
}

export default Display;
