import React from 'react';
import './App.css';
import Display from "./Display";
import LetterSound from "./LetterSound";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sound: "A.mp3",
            letter: "A"
        };
        this.onKeyDown = this.onKeyDown.bind(this);
    }


    render() {
        return (<div onKeyDown={this.onKeyDown} tabIndex={0} className="App">
            <LetterSound fileName={this.state.sound}/>
            <Display letter={this.state.letter}/>
        </div>);
    }

    onKeyDown(event) {
        console.log("Pressed: " + event.key);
        let key = event.key.toLowerCase();
        if ("abcdefghijklmnopqrstuwvxyz".includes(key)) {
            this.setState({
                letter: key.toUpperCase(),
                sound: key + ".mp3"
            });
        } else {
            console.log("Not supported letter: " + key);
        }
    }
}

export default App;
